<template>
  <div>
    <!-- 레이블 -->
    <div
      v-if="label"
      class="input-title"
    >
      {{label}}
    </div>
    <div class="input-file-container">
      <span
        v-if="buttonLabel"
        :for="id"
        :class="{'file-label': true, disabled: (fileList.length >= maxCount)}"
        @click.prevent="$refs.fileInput.click()"
      >
        {{buttonLabel}}
      </span>
      <!-- 파일 input -->
      <input
        ref="fileInput"
        type="file"
        :id="id"
        :disabled="fileList.length >= maxCount"
        class="upload-hidden"
        @change="onFileChange"
        :accept="accept"
      >
      <span class="input-desc">최대 {{ maxCount }}개까지 파일 첨부가 가능합니다. ({{ fileList.length }}/{{ maxCount }})</span>
    </div>
    <template v-for="(file,i) in fileList">
      <div
        class="input-file-container"
        :class="containerClasses"
      >
        <!-- 선택한 파일명 input -->
        <input
          type="text"
          class="upload-name"
          readonly="readonly"
          v-bind="$attrs"
          :value="file.name"
        >
        <!-- 삭제 버튼 -->
        <button
          type="button"
          class="btn-del"
          @click="onFileRemove(i)"
        ><span>x</span></button>
        <!-- 버튼 레이블 -->
      </div>
    </template>
    <div v-if="fileList.length == 0"
      class="input-file-container"
      :class="containerClasses"
    >
      <!-- 선택한 파일명 input -->
      <input
        type="text"
        class="upload-name"
        readonly="readonly"
        v-bind="$attrs"
      >
    </div>
  </div>
</template>

<script>
/**
 * 파일 Input 컴포넌트
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
 */
export default {
  name: 'FileInput',

  /**
   * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
   */
  inheritAttrs: false,

  props: {
    /**
     * 파일명
     * @model
     */
    value: {
      type: Array,
      default: []
    },

    /**
     * 레이블
     */
    label: {
      type: String,
    },

    /**
     * 버튼 레이블
     */
    buttonLabel: {
      type: String,
      default: null,
    },

    /**
     * ID
     */
    id: {
      type: String,
      default() {
        // https://stackoverflow.com/a/12502559
        return `input-${Math.random().toString(36).slice(2)}`;
      },
    },

    /**
     * 파일유형 제한
     */
    accept: {
      type: String,
      default: '.hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf'
    },

    /**
     * 유효성 검사 실패 여부
     */
    invalid: {
      type: Boolean,
      default: false,
    },

    /**
     * 최대 첨부파일 수
     */
     maxCount: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      fileData: null,
      fileList: [],
    };
  },

  mounted () {
    this.fileList = [...this.value];
  },

  computed: {
    containerClasses() {
      return {
        error: this.invalid,
      };
    },
  },

  methods: {
    /**
     * 파일 선택
     */
    onFileChange(event) {
      const fileData = event.target.files[0];
      /**
       * 파일 선택 이벤트
       * @event change
       * @property {File} 선택한 파일 https://developer.mozilla.org/en-US/docs/Web/API/File
       */
      this.$emit('change', fileData, this.id);
    },
    /** 
     * 파일 업로드 성공
     */
    onFileAdd(fileData) {
      if (this.fileList.length < this.maxCount) {
        this.fileList.push(fileData);
      }
    },
    /**
     * 파일 삭제
     */
    onFileRemove(index) {
      /**
       * 파일 삭제 이벤트
       * @event remove
       * @property {File} 선택한 파일 https://developer.mozilla.org/en-US/docs/Web/API/File
       */
      this.$emit('remove', index, this.id);
      this.fileList.splice(index, 1)
    },
  },
};
</script>

<style scoped>
.input-file-container {
  margin: 10px 0;
}
.input-file-container .upload-name {
  width: 100%;
}
.input-file-container .btn-del {
  right: 12px;
}
.input-file-container .file-label {
  margin-left: 0;
  display: inline-block;
  width: 140px;
  height: 48px;
  color: #111;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #000;
  margin-left: auto;
  transition: 0.5s;
}
.input-file-container .file-label.disabled {
  background: #f5f5f5;
  border: 1px solid #ddd;
  color: #a4a4a4;
  cursor: default;
}
.input-file-container .input-desc {
  display: inline-block;
  width: 100%;
  margin-left: 10px;
  vertical-align: bottom;
}

@media screen and (min-width: 769px) {
  .btn-del {
    background-image: url(/img/sp_sprite.86d5d759.png);
    background-size: 611px 599px;
    background-position: -295px -220px;
    transform: scale(0.7);
  }
}
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<FileInput id="file-name-1" />

<br />

<FileInput
  id="file-name-2"
  label="파일첨부"
  placeholder="파일을 첨부해주세요"
  buttonLabel="파일 선택"
  @change="(file) => showAlert(`${file.name}을 선택하였습니다.`)"
/>

<br />

<FileInput id="file-name-3" invalid />
<span class="input-error-desc">첨부할 파일을 선택해 주세요.</span>
```
</docs>
